var jstz = require('jstimezonedetect');

document.addEventListener('DOMContentLoaded', () => {
  if (document.cookie.indexOf('cookie_consent') === -1) {
    const one_year = 60*60*24*365;

    if (document.querySelector('.js-cookie-close')) {
      document.querySelector('.js-cookie-close').addEventListener('click', () => {
        document.cookie = `cookie_consent=true;path=/;max-age=${one_year};SameSite=None;Secure`;
        document.querySelector('.js-cookie-bar').style.display = "none";
      });
    }

    document.cookie = `cookie_consent=true;path=/;max-age=${one_year};SameSite=None;Secure`;
  }

  if (document.cookie.indexOf('time_zone') === -1) {
    const one_month = 60*60*24*30;
    document.cookie = `time_zone=${jstz.determine().name()};path=/;max-age=${one_month};SameSite=None;Secure`;
  }

  site.launch.init();
  site.avatar.init();
  site.announces.init();
});

document.addEventListener('turbolinks:load', () => {
  site.launch.init();
  site.helpBlock.init();
  site.avatar.init();
  site.announces.init();
});

document.addEventListener('render_async_load', () => {
  site.announces.init();
});

window.site = {
  ...window.site || {},
  avatar: {
    init() {
      window.removeEventListener("scroll", site.avatar.onScroll);

      if (!document.querySelector('body.s-user.s-customize')) return;

      window.addEventListener("scroll", site.avatar.onScroll);
    },
    onScroll() {
      const avatarHead = document.querySelector('.js-avatar-head');
      const avatarHeadFixed = document.querySelector('.js-avatar-head-fixed');

      if (avatarHead && avatarHead.getBoundingClientRect().top <= 0) {
        avatarHeadFixed.classList.remove('translate-y-full');
      } else {
        avatarHeadFixed.classList.add('translate-y-full');
      }
    }
  },
  launch: {
    init() {
      const closeButtons = document.querySelectorAll('.js-launch-close');

      closeButtons.forEach((button) => {
        ["click", "touch"].forEach(function(_event){
          button.removeEventListener("click", site.launch.close);
          button.addEventListener("click", site.launch.close);
        });
      });
    },
    close() {
      const maxAge = 60*60*24*2; // Two days
      const launch_button = document.querySelector('.js-launch');

      document.cookie = `new_launch_banner=true;path=/;max-age=${maxAge}`;
      if (launch_button) launch_button.remove();
    }
  },
  announces: {
    init () {
      site.announces.element = document.querySelector('.js-post-embed');

      document.querySelectorAll('.js-post-embed-open').forEach(element => {
        ["click", "touch"].forEach(function(event){
          element.removeEventListener(event, site.announces.open);
          element.addEventListener(event, site.announces.open);
        });
      });

      document.querySelectorAll('.js-post-embed-close').forEach(element => {
        ["click", "touch"].forEach(function(event){
          element.removeEventListener(event, site.announces.close);
          element.addEventListener(event, site.announces.close);
        });
      });
    },
    close () {
      site.announces.element.classList.remove('fixed');
      site.announces.element.classList.add('hidden');
      document.querySelector('.js-post-embed-widget').classList.add('translate-x-full');
    },
    open() {
      site.announces.element.classList.remove('hidden');
      site.announces.element.classList.add('fixed');
      setTimeout(() => {
        document.querySelector('.js-post-embed-widget').classList.remove('translate-x-full');
      }, 100);

      document.cookie = `last_seen_announces=${new Date()};path=/;max-age=${60*60*24*30}`;
    }
  },
  helpBlock: {
    init () {
      const helpBlockOpen = document.querySelectorAll('.js-help-block-open');
      const helpBlockClose = document.querySelectorAll('.js-help-block-close');

      helpBlockOpen.forEach(element => {
        ["mouseup", "touch"].forEach(function(event){
          element.removeEventListener(event, (e) => site.helpBlock.open(e, element));
          element.addEventListener(event, (e) => site.helpBlock.open(e, element));
        });
      });

      helpBlockClose.forEach(element => {
        ["mouseup", "touch"].forEach(function(event){
          element.removeEventListener(event, () => site.helpBlock.close(element));
          element.addEventListener(event, () => site.helpBlock.close(element));
        });
      });
    },
    open (event, element) {
      event.preventDefault();
      const helpBlock = document.getElementById(element.hash.replace('#', ''));

      if (helpBlock.classList.contains('hidden')) {
        helpBlock.classList.remove('hidden');
      } else {
        helpBlock.classList.add('hidden');
      }
    },
    close (element) {
      element.closest('.js-help-block').classList.add('hidden');
    }
  }
}
